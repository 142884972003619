//TODO: Cleanup this file when time permits and remove this comment after that

import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import QrCode from "../../../components/QrCodeAndDownload.component";
import AssetOverviewTab from "./AssetOverviewTab.component";
import ImageUpload from "../../../components/ImageRenderUpload.component";
import UpdateButtons from "../../../components/UpdateButtons.component";
import AdjustHourMeterModal from "./Modal/AdjustHourMeterModal";

import { toEditAssetName, resetState } from "../../../redux/asset/asset.action";
import SnackBar from "../SnackBar";
import { toAddAssetImage } from "../../../redux/asset/asset.action";
import {
  assetStatusUpdate,
  resetAllAssetStatus,
  toGenerateCSV,
  updateAsset,
  updateAssetItem,
} from "../../../redux/assets/action";

import { setEditForm } from "../../../redux/forms/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import alertConstant from "../../../constant/messages/toaster.json";
import UnlockModal from "../../Modals/UnlockModal";
import { apiCall } from "../../../utils/apiCall";
import AssetsTabBar from "./Tabs/AssetsTabBar.component";
import AssetOverviewChecklistTable from "./AssetOverviewChecklistTable.component";
import AssetMediaTab from "./AssetMediaTab.component";
import AssetOverviewInspectionReports from "./AssetOverviewInspectionReports.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
// custom css for mobile view
import AssetSectionStyles from "./AssetSectionStyle.module.css";
import Button from "../../../components/Button/Button.component";
import {
  ButtonLocked,
  ButtonUnLocked,
  ManagementPanelWrapper,
  styles,
} from "./AssetOverview.component.styles";
import { CacheRefs } from "../../../utils/cache/CacheRefs";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const qrType = require("../../../constant/qrType");
import config from "../../../config";
const s3BucketAssetImg = config.s3BucketAssetImg; //User profile image s3 path

const stylesCss = {
  message: {
    color: "rgba(0,0,0,0.5)",
  },
};

const AssetOverview = ({
  assetOverview,
  success,
  resetState,
  loginDetails,
  assetStatusUpdate,
  assetStatusSuccess,
  resetAllAssetStatus,
  AssetLoading,
  AssetDetailLoading,
  updateAsset,
  updateAssetItem,
  setEditForm,
  editForm,
  setEditAlertModal,
  assetImageLoading,
  assetImageSuccess,
  toAddAssetImage,
  accountListById,
  isSuperAdmin,
  toGenerateCSV,
  generateCSVData,
  generateCSVLoading,
  assetStatusData,
  assetStatusFailed,
}) => {
  //const AssetStatus = assetOverview ? assetOverview?.AssetStatus : true;
  const [statusButton, setStatusButton] = useState(assetOverview?.AssetStatus);
  const [AssetName, setAssetName] = useState("");
  const [IsSubmitted] = useState(false);
  const [PreviewImage, setPreviewImage] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setErrorSuccess] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [activeTab, setActiveTab] = useState("assetOverview");
  const [showAdjustMeterHourModal, setshowAdjustMeterHourModal] = useState(false);
  const { t } = useTranslation();

  const [showAlertModal, setShowAlertModal] = useState(false);
  //const [disableSwitch, setDisableSwitch] = useState(false);

  const changeStatus = (e) => {
    if (editForm) {
      let id = e.target.id;
      //setDisableSwitch(true);
      let AssetStatus = e.target.checked;
      assetStatusUpdate(id, { AssetStatus });
      //  setStatusButton(!statusButton);
    } else {
      setEditAlertModal(true);
    }
  };
  useEffect(() => {
    if (assetStatusFailed) {
      setShowAlertModal(true);
      //setDisableSwitch(false);
    }
  }, [assetStatusFailed]);

  useEffect(() => {
    if (assetStatusSuccess) {
      setStatusButton(!statusButton);
    }
  }, [assetStatusSuccess]);

  const labelStyle = {
    position: "relative",
    height: "0px",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: "#919191",
    fontFamily: "Open Sans, sans-serif",
  };
  const handleUpdate = (event) => {
    let assetForm = document.getElementById("assetOverviewEdit");
    if (assetForm.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    event.preventDefault();

    setIsLoading(true);
    let {
      AssetStatus,
      // s3enabled,
      _id,
      assetName,
      assetDescription,
      assetAttributes,
      accountId,
      assetImage,
      assetMedia,
      productType,
      siteId,
    } = editData;
    let newPayload = {
      AssetStatus,
      // s3enabled,
      _id,
      assetName,
      assetDescription,
      assetAttributes,
      accountId,
      assetImage,
      assetMedia,
      productType,
      siteId,
    };

    apiCall(`/asset/updateasset/${event.target.id}`, { method: "PUT", data: newPayload })
      .then((response) => {
        if (response.data.success === true) {
          let { name, manager, timeZone, _id: siteId } = response?.data?.data?.siteId;
          updateAsset({
            _id,
            assetName,
            productType,
            siteId: { name, manager, timeZone, _id: siteId },
            assetAttributes,
          });
          setAssetName(assetName);
          setIsSuccess(true);
          setIsLoading(false);
          setEditForm(true);

          //below is to refresh the export data api after a asset channge
          CacheRefs.removeCacheForAssets();
          toGenerateCSV();
        } else {
          setErrorSuccess(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setErrorSuccess(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (assetOverview) {
      setAssetName(assetOverview.assetName);
      setPreviewImage(assetOverview.assetImage);
    }

    setStatusButton(assetOverview?.AssetStatus);
  }, [assetOverview]);

  const handleCloseSnackBar = () => {
    resetState();
    setIsSuccess(false);
    setErrorSuccess(false);
    resetAllAssetStatus();
    //setDisableSwitch(false);
  };

  useEffect(() => {
    //reset everything on unload
    return () => {
      handleCloseSnackBar();
    };
  }, []);
  const handleMessage = (success, assetImageSuccess, assetStatusSuccess, IsSuccess) => {
    if (success) {
      return t(alertConstant.asset_updated_successful);
    } else if (assetImageSuccess) {
      //  return t(alertConstant.asset_updated_successful);
    } else if (IsSuccess) {
      return t(alertConstant.asset_updated_successful);
    } else if (IsError) {
      return t(alertConstant.asset_updated_error);
    } else if (assetStatusSuccess) {
      if (statusButton) {
        return t("AssetActivatedMessage");
      } else {
        return t("AssetDeactivatedMessage");
      }
    }
  };

  let timeZone = assetOverview && assetOverview.siteId && assetOverview.siteId.timeZone;

  const messageLabel = handleMessage(success, assetImageSuccess, assetStatusSuccess, IsSuccess);
  return (
    <ManagementPanelWrapper>
      <Card className={`worksite-height ${assetOverview?.isLocked && "danger-outline"}`}>
        <UnlockModal
          modalShow={showModal}
          updateItem={(data) => {
            updateAssetItem(data);
          }}
          handleModalClose={() => setShowModal(false)}
          token={loginDetails?.token}
          data={selectedAsset}
        />
        <Card.Body>
          {(AssetLoading || AssetDetailLoading) && (
            <Row className="assetTopBarH">
              <Col lg={2}>
                <Skeleton circle={true} height={90} width={100} />
              </Col>
              <Col lg={4}>
                <Skeleton height={40} />
                <Skeleton height={10} count={3} />
              </Col>
              <Col lg={2}>
                <Skeleton height={100} />
              </Col>
              <Col lg={2}></Col>
              <Col lg={2}>
                <Skeleton height={60} width={60} />
              </Col>
            </Row>
          )}
          {assetOverview && !AssetLoading && !AssetDetailLoading && (
            <Row className="assetTopBarH">
              <Col lg={2} className="ImageUpl_sec">
                <ImageUpload
                  id={assetOverview._id}
                  updateImage={toAddAssetImage}
                  imageLoading={assetImageLoading}
                  src={PreviewImage ? s3BucketAssetImg + PreviewImage : ""}
                  key={PreviewImage}
                />
              </Col>

              <Col
                lg={4}
                className={`assetTextData ${AssetSectionStyles.UserDetailsTab}`}
                style={{ marginTop: "-5px", zIndex: editForm ? 0 : 200 }}
              >
                {!editForm ? (
                  <div style={{ marginTop: "-8px" }}>
                    <label style={labelStyle}>
                      <Trans>Asset Name</Trans>
                    </label>
                    <Form.Control
                      value={editData ? editData.assetName : ""}
                      minLength={"1"}
                      maxLength={"64"}
                      onChange={(e) => setEditData({ ...editData, assetName: e.target.value })}
                      error={IsSubmitted && !AssetName}
                      helperText={IsSubmitted && !AssetName && t("Asset Name is required")}
                    />
                  </div>
                ) : (
                  <div style={styles.titleName} className="title-style text_Repo_elips">
                    <span title={AssetName && AssetName}> {AssetName} </span>
                  </div>
                )}
                <div className="asset-type-size text_Repo_elips" style={styles.date}>
                  <Trans>Asset Type</Trans>:{" "}
                  <span
                    className="elip-span"
                    title={
                      assetOverview && assetOverview.assetTypeId && assetOverview.assetTypeId.AssetTypeName
                    }
                  >
                    {assetOverview && assetOverview.assetTypeId && assetOverview.assetTypeId.AssetTypeName}{" "}
                  </span>
                </div>
                <div className="asset-type-size text_Repo_elips" style={styles.date}>
                  <Trans>Added On</Trans>:
                  <span>
                    {" "}
                    {timeZone ? (
                      <>
                        {moment(assetOverview.createdAt)
                          .utcOffset(timeZone && timeZone.split && timeZone.split(" ")[0])
                          .format("MMMM DD YYYY")}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="asset-type-size text_Repo_elips" style={styles.date}>
                  <Trans>Last Hour Meter Reading</Trans>:{" "}
                  <span>
                    {assetOverview?.lastHourMeterReading || assetOverview?.lastHourMeterReading == 0
                      ? assetOverview.lastHourMeterReading
                      : t("NA")}
                  </span>
                </div>
                <div className="asset-type-size text_Repo_elips" style={styles.date}>
                  <Trans>Worksite</Trans>:{" "}
                  <span
                    className="ellipseStyle"
                    title={assetOverview && assetOverview.siteId && assetOverview.siteId.name}
                  >
                    {assetOverview && assetOverview.siteId && assetOverview.siteId.name}
                  </span>
                </div>

                {isSuperAdmin && assetOverview?.accountId && (
                  <div style={styles.date} className="asset-type-size text_Repo_elips">
                    <Trans>AccountLabel</Trans>{" "}
                    <span title={accountListById?.[assetOverview?.accountId]?.companyName}>
                      {" "}
                      {accountListById?.[assetOverview?.accountId]?.companyName}
                    </span>
                  </div>
                )}
              </Col>

              <Col lg={2} className={`${AssetSectionStyles.QRCodeDetailsT}`}>
                <QrCode qrFor={qrType.ASSET} qrDetail={assetOverview} bindUrl={true} />
              </Col>
              <Col lg={2} className={`${AssetSectionStyles.DisplayNonediv}`}></Col>

              <Col lg="2" className={`${AssetSectionStyles.assetStatusdiv}`}>
                <PermissionCheck
                  section={"DASHBOARD"}
                  permissionName={"ASSET_MANAGEMENT"}
                  actionName={"deactivate"}
                >
                  <>
                    <p className="m-0 statusStyle">
                      <Trans>Status</Trans>
                    </p>

                    <SwitchComponent
                      id={assetOverview ? assetOverview._id : ""}
                      checked={statusButton}
                      onChange={changeStatus}
                    />
                  </>
                </PermissionCheck>
              </Col>
            </Row>
          )}

          {!assetOverview && !AssetLoading && !AssetDetailLoading && (
            <>
              <div className="noSection">
                <div align="center" style={stylesCss.message}>
                  <Trans>NO_ASSET_IN_ACCOUNT</Trans>
                </div>
                <div align="center" style={stylesCss.message}>
                  <div className="clickDesktop">
                    <Trans>CLICK_ADD_ASSET_BUTTON</Trans>
                  </div>
                  <div className="tapMobile">
                    <Trans>CLICK_ADD_ASSET_BUTTON_MOB</Trans>
                  </div>
                </div>
              </div>
            </>
          )}

          {assetOverview && !AssetLoading && !AssetDetailLoading && (
            <>
              <div style={{ marginTop: "-10px" }} className="assetTableView">
                <>
                  <AssetsTabBar activeTab={activeTab} setActiveTab={setActiveTab} />
                </>
              </div>
              <div>
                {activeTab === "assetOverview" && (
                  <AssetOverviewTab
                    validated={validated}
                    editData={editData}
                    setEditData={setEditData}
                    editForm={editForm}
                    statusButton={statusButton}
                    setShowEditButton={setShowEditButton}
                  />
                )}
                {activeTab === "assetChecklists" && (
                  <AssetOverviewChecklistTable statusButton={statusButton} />
                )}
                {activeTab === "assetMedia" && (
                  <AssetMediaTab assetOverview={assetOverview} statusButton={statusButton} />
                )}
                {activeTab === "inspectionReports" && (
                  <AssetOverviewInspectionReports assetId={assetOverview ? assetOverview._id : null} />
                )}
              </div>

              <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"edit"}>
                <Row className="editWorks">
                  {activeTab === "assetOverview" && (
                    <>
                      <Col className="text-left">
                        {assetOverview?.isLocked ? (
                          <div className="mr-2 pl-0 float-left">
                            <ButtonUnLocked>
                              <Button
                                iconClass="unlock"
                                label={t("Unlock Asset")}
                                onClick={() => {
                                  setShowModal(true);
                                  setSelectedAsset(assetOverview);
                                }}
                                showIcon
                                buttonType="secondary"
                              />
                            </ButtonUnLocked>
                          </div>
                        ) : (
                          <div className="mr-2 pl-0 float-left">
                            <ButtonLocked>
                              <Button
                                iconClass="locked"
                                label={t("Lock Asset")}
                                onClick={() => {
                                  setShowModal(true);
                                  setSelectedAsset(assetOverview);
                                }}
                                showIcon
                                buttonType="alert"
                                disabled={!statusButton}
                              />
                            </ButtonLocked>
                          </div>
                        )}
                        <div className="mr-2 pl-0 float-left">
                          <Button
                            iconClass="asset-hour-adjust"
                            label={t("Adjust Hour Meter")}
                            onClick={() => {
                              setshowAdjustMeterHourModal(true);
                            }}
                            showIcon
                            buttonType="secondary"
                            disabled={!statusButton}
                          />
                        </div>
                      </Col>

                      <Col className="assetOverEditBut">
                        <UpdateButtons
                          editOverview={editForm}
                          setEditOverview={setEditForm}
                          statusButton={statusButton}
                          handleUpdate={handleUpdate}
                          IsLoading={IsLoading}
                          overviewDetail={assetOverview}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </PermissionCheck>
            </>
          )}
          <AdjustHourMeterModal
            modalShow={showAdjustMeterHourModal}
            setModalShow={setshowAdjustMeterHourModal}
            setModalShowClose={() => setshowAdjustMeterHourModal(false)}
            editData={editData}
            currentHourMeterReading={assetOverview?.lastHourMeterReading || 0}
          />
        </Card.Body>
        <SnackBar
          isSuccess={
            (success || //|| assetImageSuccess
              assetStatusSuccess ||
              IsSuccess) &&
            t("AssetDeactivatedMessage") !== messageLabel
          }
          isFailed={t("AssetDeactivatedMessage") === messageLabel}
          label={messageLabel}
          handleClose={handleCloseSnackBar}
        />
      </Card>
    </ManagementPanelWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  success: state.asset.Success,
  loading: state.asset.Loading,
  assetImageLoading: state.assets.AssetImageLoading,
  assetImageSuccess: state.assets.AssetImageSuccess,
  assetImageError: state.assets.AssetImageError,
  assetStatusSuccess: state.assets.AssetStatusSuccess,
  assetStatusMessage: state.assets.AssetStatusMessage,
  AssetLoading: state.assets.AssetLoading,
  AssetDetailLoading: state.assets.assetDetailLoading,
  editForm: state.forms.editForm,
  accountListById: state.dropdownFilters.accountListById,
  isSuperAdmin: state.user.isSuperAdmin,
  generateCSVData: state.assets.GeneratingCSVData,
  generateCSVLoading: state.assets.GenerateCSVLoading,
  assetStatusData: state.assets.assetStatusData,
  assetStatusFailed: state.assets.assetStatusFailed,
});

const mapDispatchToProps = (dispatch) => ({
  toEditAssetName: (id, data) => dispatch(toEditAssetName(id, data)),
  resetState: () => dispatch(resetState()),
  toAddAssetImage: (data, id) => dispatch(toAddAssetImage(data, id)),
  assetStatusUpdate: (id, status) => dispatch(assetStatusUpdate(id, status)),
  resetAllAssetStatus: () => dispatch(resetAllAssetStatus()),
  updateAsset: (data) => dispatch(updateAsset(data)),
  updateAssetItem: (data) => dispatch(updateAssetItem(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  toGenerateCSV: () => dispatch(toGenerateCSV()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetOverview);
